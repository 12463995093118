<template>
	<div class="body-box clearfix" :style="s">
		<body-left></body-left>
   
		<body-right></body-right>
	</div>
</template>

<script>
import Event from '../../scripts/event.js'
import BodyLeft from '../layout/bodyleft.vue'
import BodyRight from '../customerlayout/customerbodyright.vue'

export default{
	data : function(){
		return {
			s:{
				height : 0
			}
		}
	},
	methods : {
		changeBodyH : function(){
			this.s.height = this.beforeCreateData-2 + "px";
		}
	},
	beforeCreate : function(){
		var _this = this;
		Event.$on("changeHeight",function(msg){
			_this.beforeCreateData = window.innerHeight - parseInt(msg,10);
			_this.changeBodyH();
		})
	},
	mounted : function(){
		this.changeBodyH();
	},
	components : {
		"body-left" : BodyLeft,
		"body-right" : BodyRight
	}
}
</script>

