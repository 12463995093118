<template>
	<div class="body-right-box" :class="{'close-left-box':isCloseLeftBox}">
		<div class="tag-list">
			<div class="tag-scroll" ref="tagS">
				<ul class="clearfix" ref="tagW" :style="{ width: tagWidth }">
					<li v-for="(item,index) in tagList" :class="{'tagcur':item.isShow}" @click="changePage(item)">
						<span class="tag-text" v-text="item.name"></span>
						<!-- <router-link :to="item.path" class="tag-text">{{item.name}}</router-link>-->
						<a href="javascript:;" @click.stop="closePage(index)" v-if="index>0" class="tag-close"></a>
					</li>
				</ul>
				<div class="tag-left-right" v-show="tagBtnShow">
					<a href="javascript:void(0)" class="tag-left" @click="leftScroll">&lt;</a>
					<a href="javascript:void(0)" class="tag-right" @click="rightScroll">&gt;</a>
				</div>
			</div>
		</div>
		<div class="right-content" :style="s">
			<transition-group name="el-zoom-in-bottom">
				<component :is="citem.component" v-for="citem in tagList" :key="citem.name" v-show="citem.isShow">
				</component>
			</transition-group>
		</div>
		<el-dialog title="免责申明提示" :visible.sync="dialogVisible" width="400px" :before-close="handleClose" :modal="true"
			size="small">
			<span style="line-height:25px;">本环境为多用户共享环境，仅用于演示，请不要在系统中提交任何与您业务相关或者比较私密的信息。请<span type="text"
					@click="dialogVisible=fasle; dialogDetailVisible = true"
					style="text-decoration:underline;cursor: pointer;">点击浏览完整免责条款</span>。</span></span>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogVisible = false">确 定</el-button>
			</span>
		</el-dialog>
		<el-dialog title="免责申明详情" :visible.sync="dialogDetailVisible" width="820px" height="550px"
			:before-close="handleClose" :modal="true">
			<div style="line-height:25px;  overflow:auto;height: 450px;overflow-x:visible">

				<p style="text-align: center;">物联云仓（成都）科技有限公司</p>
				<p style="text-align: center;">免责声明</p>
				<pre>
                提醒条款：
                尊敬的用户，感谢您使用物联云仓订单管理SaaS系统（以下简称“本系统”），本系统系由物联云仓（成都）科技有限公司（以下简称
                “我公司”）开发并运营，用于向国内企业提供仓配信息化等服务。为更好地向您提供服务，我公司特向您提供了测试账号，以供您提
                前熟悉本系统，并可在本系统中进行演示和试运行，在此特别提醒您：演示环境处于共享状态，您上传的所有信息均可能被本系统其
                他用户知悉和获取，因此请慎重上传您的相关信息，请勿上传您的保密信息，也请遵守我公司的服务协议及免责条款等有关内容，避
                免侵犯他人商业秘密等。测试期结束后，我公司将另行向您提供专属于您的账号，届时我公司将严格履行相关保密责任，避免您的任
                何保密信息被泄露，您可放心使用本系统。
                一、免责声明：
                1.1我公司可根据法律法规或业务需要随时单方面修改本免责声明，并以公告方式进行通知。请关注系统中的公告信息，以了解对您具有
                约束力的条款是否有所修改。
                1.2若您违反本声明任何内容，或我公司合理怀疑您的行为违反适用法律或损害本系统及其他用户的利益，我公司可在您获知或不获知的
                情况下终止您进入本系统及使用本系统服务的权利，并采取相应法律措施。
                1.3若您违反本声明第二条内容，所造成的任何损失及不利影响，将均由您自行承担，我公司免于承担任何责任。
                二、免责范围
                2.1在我公司明示本系统仍仅用于测试期间，您在本系统中上传、保存、修改、知悉、获取的所有信息，我公司均不承担保密义务，请勿
                上传与您有关的任何保密信息、违法信息（包括但不限于不当言论、黄赌毒信息、违规广告）等，也请您尊重他人的商业秘密和信息安全。
                2.2在我公司明示本系统仍仅用于测试期间，因他人获取了您所上传的信息而给您或其他任何人造成的损失，或因您违规上传保密信息、
                违法信息等给自身或他人造成的损失，或因您获取了他人上传的信息而给您或他人造成的损失，我公司均不承担责任。
                2.3在我公司明示本系统仍仅用于测试期间，演示环境数据可能会不定期清除，请您不要在演示环境上传正式业务数据，我公司不对数据
                清除承担责任；
                2.4由于您将账号、密码告知他人或与他人共享账号，导致您的信息、资料泄露或造成其他损失的，我公司不承担任何责任。
                2.5您所上传的信息、资料中若包含您或他人的著作权、商标权等知识产权的，均与我公司无关，由此造成的您或他人知识产权受到损害
                的，我公司均不承担任何责任。
                2.6您明确理解和同意，我公司不对因下述任一情况而发生的任何损害赔偿承担责任，包括但不限于因下述情况造成您利润、商誉、使用、
                数据等方面的损失或其他无形损失的损害赔偿：
                ①使用或未能使用本系统服务；
                ②通过使用本系统服务而下载或以其他形式获取的任何内容导致您的电脑系统的任何损毁或任何数据损失；
                ③任何第三人对您的承诺或基于本系统对您实施的行为；
                ④因不可抗力原因造成的任何后果；
                ⑤因本系统常规或紧急的设备与系统维护、设备与系统故障、网络信息与数据安全等因素；
                ⑥由于计算机或服务器问题、黑客攻击、计算机病毒侵入或发作；
                ⑦您违反我国相关法律法规禁止性规定的行为。
                2.7我公司在现有技术水平及条件下维护本系统服务的稳定性及安全性，但我公司对下述内容不作保证：
                ①本系统服务会完全符合您的要求；
                ②本系统服务不会中断或终断，且不带任何错误；
                ③您通过使用本系统服务而可能获取的结果将是完全符合您预期的。
                2.8 禁止对本系统进行攻击、复制、镜像等行为，对于尝试通过各种手段对本系统进行侵犯的行为，我公司保留追究您法律责任的权利。
                2.9 禁止通过对本系统进行研究模仿，以此用于创造类似的产品，如果侵犯我公司软件著作权，我公司保留追究您法律责任的权力。
                2.10 演示账号正常情况下有效期为5个工作日，到期我们将对账号做关停处理，但我方保留随时关停账号的权利，演示期间产生的任何数
                据，我方不提供任何关于保留、导出、删除等额外服务的承诺；
                三、本免责条款最终解释权归我公司所有。
            </pre>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="dialogDetailVisible = false">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import Event from '@/scripts/event.js';
	import RouteManage from '@/scripts/routemanage';
	import Cookie from '@/scripts/cookie.js';
	import config from '@/scripts/config.js';
	import Store from '@/scripts/store.js'

	export default {
		data: function() {
			var domain = '';
			domain = Cookie.get(Cookie.domain);

			var vPath = `/${config.subdomain}/${domain}`;

			return {
				s: {
					height: 0
				},
				tagWidth: 'auto',
				tagBtnShow: false,
				tagList: [{
					name: "工作台",
					path: vPath + "/home",
					isShow: true,
					zIndex: 999,
					component: resolve => require([
						'../../components/business/workbench/customerworkbench.vue'], resolve)
				}],
				isCloseLeftBox: false,
				dialogDetailVisible: false,
				dialogVisible: false,
			}
		},
		beforeCreate: function() {
			var _this = this;
			Event.$on("changeContentHeight", function(msg) {
				_this.beforeCreateData = window.innerHeight - parseInt(msg, 10) - 38;
				_this.changeBodyH();
				_this.DisplayDisclaimer();
			})
		},
		watch: { //深度 watcher
			tagList: {
				handler: function(val) {
					var tw = 111;
					for (var i = 0; i < val.length; i++) {
						if (i > 0) {
							tw = tw + parseInt(val[i].name.length * 13 + 61);
						}
					}
					this.tagWidth = tw + 'px';
					if (parseInt(this.tagWidth) > this.$refs.tagS.clientWidth) {
						this.tagBtnShow = true;
						// this.$refs.tagS.scrollLeft = parseInt(this.tagWidth) - this.$refs.tagS.clientWidth;
						var self = this;
						setTimeout(function() {
							self.leftScroll(parseInt(self.tagWidth) - self.$refs.tagS.clientWidth)
						}, 10);

					} else {
						this.tagBtnShow = false;
					}

				}
			}
		},

		methods: {
			changeBodyH: function() {
				this.s.height = this.beforeCreateData + "px";
			},
			changePage: function(obj) {
				for (var i = 0; i < this.tagList.length; i++) {
					this.tagList[i].isShow = false;
					this.tagList[i].zIndex = 1;
				}
				obj.isShow = true;
				obj.zIndex = 999;
			},
			closeAllTag: function() {
				this.tagList[0].isShow = true;
				this.tagList[0].zIndex = 999;
				this.$router.push({
					path: this.tagList[0].path
				});
				this.tagList.splice(1, this.tagList.length - 1);

			},
			reloadAllTag: function() {
				var child = this.$children[0];
				if (child && child.$children && child.$children.length > 1) {
					for (var i = 1; i < child.$children.length; i++) {
						var component = child.$children[i];
						if (component != null && component.init != undefined) {
							component.init();
							component.$refs["control"].initialize();
						}
					}
				}
			},
			closePage: function(index) {
				if (this.tagList[index].isShow && index > 0) {
					this.tagList[index - 1].isShow = true;
					this.tagList[index - 1].zIndex = 999;

					this.$router.push({
						path: this.tagList[index - 1].path
					});
				}
				this.tagWidth = parseInt(this.$refs.tagW.style.width) - $(this.$refs.tagW.children[index]).width() +
					'px';

				this.tagList.splice(index, 1);

			},
			leftScroll: function(len) {
				if (isNaN(len)) {
					this.$refs.tagS.scrollLeft = this.$refs.tagS.scrollLeft - 50;
				} else {

					this.$refs.tagS.scrollLeft = len;
				}
			},
			rightScroll: function() {
				this.$refs.tagS.scrollLeft = this.$refs.tagS.scrollLeft + 50;
			},
			handleClose: function() {
				//prevent close
			},
			DisplayDisclaimer: function() {
				var domain = '';
				domain = Cookie.get(Cookie.domain);
				// this.$AjaxRequest.AjaxSubmit("omsapi/disclaimer?domain=" + domain, "get", {}, result => {
				// 	if (result.Result == true) {
				// 		this.dialogVisible = true;
				// 	}
				// }, null, true);
			},
		},
		mounted: function() {
			var _this = this;
			Event.$on("closeAllTag", function() {
				_this.closeAllTag();
			});
			Event.$on("reloadAllTag", function() {
				_this.reloadAllTag();
			});
			Event.$on("addTag", function(obj, isOrder) {
				var isOrderAdd = false;
				if (isOrder != null && isOrder != undefined) {
					if (isOrder) {
						if (_this.tagList != null && _this.tagList.length > 0) {
							var index = _this.tagList.findIndex(f => f.name == obj.name);
							if (index > -1) {
								_this.closePage(index);
								isOrderAdd = true;
							}
						}
					}
				}
				var isSame = null;
				for (var k = 0; k < _this.tagList.length; k++) {
					var item = _this.tagList[k];
					var path = item.path;
					if (path.indexOf("?") > -1) {
						path = path.slice(0, path.indexOf("?"));
					}
					if (`/${ config.subdomain }${obj.id}` === path) {
						isSame = item;
					} else {
						_this.tagList[k].isShow = false;
						_this.tagList[k].zIndex = 1;
					}
				}
				if (isSame == null) {
					var objPath = `/${ config.subdomain }${obj.path}`;
					const name = obj.name;
					var o = {};
					o.name = obj.name;
					o.path = objPath;
					o.isShow = true;
					o.id = obj.id;
					o.zIndex = 999;

					var domain = '';
					domain = Cookie.get(Cookie.domain);
					var vPath = `/${ config.subdomain }/${domain}`;
					var data = RouteManage.routes;
					console.log(data);
					console.log(vPath);
					o.component = RouteManage.routes.find(n => n.path == vPath + "/").children.find(n => n
						.path == objPath).component;
					_this.tagList.push(o);
					_this.$router.push(o.path);

				} else {
					isSame.isShow = true;
					isSame.zIndex = 999;
					_this.$router.push(`/${ config.subdomain }${obj.path}`);
				}

				if (isOrderAdd) {
					this.Utils.lazy(() => {
						Event.$emit("refreshOrderKey");
					});
				}
			});
			Event.$on("isCloseLeftBox", function() {
				_this.isCloseLeftBox = !_this.isCloseLeftBox;
			});
		}
	}
</script>

<style scoped>

</style>