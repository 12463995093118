<template>
    <div>
        <my-header></my-header>
            <my-body></my-body>
</div>
</template>
<script>

    import Vue from 'vue';
    //import ElementUI from 'element-ui';
    //import 'element-ui/lib/theme-default/index.css';
    import Header from '../layout/header.vue';
    import Body from '../customerlayout/customerbody.vue';

    //Vue.use(ElementUI);


    export default {
        components: {
            "my-header": Header,
            "my-body": Body
        }
    };
</script>

<style>
    html, body {
        min-width: 980px;
    }

    html, body, div, span, applet, object, iframe,
    h1, h2, h3, h4, h5, h6, p, blockquote, pre,
    a, abbr, acronym, address, big, cite, code,
    del, dfn, em, img, ins, kbd, q, s, samp,
    small, strike, strong, sub, sup, tt, var,
    b, u, i, center,
    dl, dt, dd, ol, ul, li,
    fieldset, form, label, legend,
    table, caption, tbody, tfoot, thead, tr, th, td,
    article, aside, canvas, details, embed,
    figure, figcaption, footer, header, hgroup,
    menu, nav, output, ruby, section, summary,
    time, mark, audio, video {
        margin: 0;
        padding: 0;
        border: 0;
        font-size: 100%;
        font-family: inherit;
        vertical-align: baseline;
        font-style: normal;
    }
    /* HTML5 display-role reset for older browsers */
    article, aside, details, figcaption, figure,
    footer, header, hgroup, menu, nav, section {
        display: block;
    }

    body {
        line-height: 1;
        font-size: 12px;
        font-family: "微软雅黑";
        color: #333;
    }

    ol, ul {
        list-style: none;
    }

    blockquote, q {
        quotes: none;
    }

        blockquote:before, blockquote:after,
        q:before, q:after {
            content: '';
            content: none;
        }

    table {
        border-collapse: collapse;
        border-spacing: 0;
    }

    a {
        text-decoration: none
    }

        a:hover {
            text-decoration: none;
        }

    input {
        font-family: "微软雅黑";
    }

    .clearfix:after {
        content: ".";
        display: block;
        height: 0;
        clear: both;
        visibility: hidden;
    }

    .clearfix {
        display: inline-block;
    }
    /*  Hides from IE-mac  \*/
    *html .clearfix {
        height: 1%;
    }

    .clearfix {
        display: block;
    }
</style>
